import React, { Component } from 'react'
import { Router, Redirect } from '@reach/router'
import { Home, Story, SelfTips } from './components/pages'

export default class Routes extends Component{
  render() {
    return (
        <Router>
          <Home path="/" />
          <Stories path="stories">
            <Story path="/" />
          </Stories>
          <SelfTips path="/tips" />
          <Redirect from="/home" to="/" noThrow />
        </Router>
      )
    }
}

const Stories = (props) => {
  return props.children
}

