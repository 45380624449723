import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Helmet } from "react-helmet"

export const LazyLoad = (img) => {
 return <LazyLoadImage alt={img.alt} effect="blur" src={img.src} wrapperClassName={img.className}/>
}

export const SideNav = props => {

 return (
    <div className="flex flex-col justify-center lg:w-5/12 md:w-full lg:order-last md:order-first sm:order-first lg:items-end md:items-center sm:items-center lg:fixed fixed-dp">
      <div className="flex flex-col items-center justify-center lg:items-end md:items-center sm:items-center">
        <LazyLoad className="inline w-1/5 h-auto lg:w-1/5 md:w-1/5 sm:w-1/4 alvin-dp" src={require('./images/alvin_dp_2.jpeg')} alt="Alvin Profile"/>
      </div>
      <div className="flex flex-col items-center justify-center mt-3 text-center lg:items-end md:items-center sm:items-center">
        <a href="https://www.buymeacoffee.com/alvinrapada" target="blank" className="px-6 py-2 mt-3 mb-20 text-base text-white bg-theme-color rounded hover:shadow-md focus:shadow-outline focus:outline-none">
          Buy me a coffee <span role="img" aria-label="coffee">☕️</span>
        </a>
      </div>
    </div>
 )
}

export const SeoHelmet = (props) => {
  return (
    <Helmet>
      <title>Alvin Rapada | {props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content="programming, elixir, react, web development, mobile development, alvin rapada, software, engineer, software engineer" />
      <meta name="author" content="Alvin Rapada | www.alvinrapada.com" />
      <meta property="og:title" content={"Alvin Rapada | " + props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image || require('./images/alvin_dp_1.png')} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta property="og:url" content={"www.alvinrapada.com" + props.link} />
    </Helmet>
  )
}
